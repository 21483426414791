import React, { Component } from 'react';
import reactFeature from '../../assets/images/react-feature.svg';
import sassFeature from '../../assets/images/sass-feature.svg';
import bootstrapFeature from '../../assets/images/bootstrap-feature.svg';
import responsiveFeature from '../../assets/images/responsive-feature.svg';
import { Card, Button,
  CardHeader,
  CardFooter,
  CardImg,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  ModalHeader,
  CardText, CardBody, Row, Col, Progress } from 'reactstrap';
import { launcherHelper, SettingsHelper } from '../../phoenix/phoenixhelpers';
import Settings from '../pages/Settings';

class Battlefield2 extends Component {

  constructor() {
    super();
    this.state = {
      dropdownOpen: false,
      state: 0,
      updaterStr: null,
      updaterProc: 0,
      windowed: false,
      widescreen: false,
      autologin: true,
      intro: false,
      settingsModalOpen: false,
      isDonator: false,
    }
    this.onStateChanged = this.onStateChanged.bind(this);
    this.onProgressChanged = this.onProgressChanged.bind(this);
    this.shouldDisable = this.shouldDisable.bind(this);
    this.getButtonText = this.getButtonText.bind(this);
    this.getLabelText = this.getLabelText.bind(this);
    this.handleMainButtonPress = this.handleMainButtonPress.bind(this);
    this.handleChangeSetting = this.handleChangeSetting.bind(this);
  }

  async componentDidMount() {
    await launcherHelper.switchGame("Battlefield2");
    let state = await launcherHelper.loadGameState("Battlefield2");
    console.log(state);
    if(this.state.state == 0)
    {
      this.setState({
        state: state.uState
      });
    }
    window.addEventListener('stateChanged', this.onStateChanged, false);
    window.addEventListener('progressChanged', this.onProgressChanged, false);
    let windowed = await SettingsHelper.getSetting("windowed");
    let widescreen = await SettingsHelper.getSetting("widescreen");
    let autologin = await SettingsHelper.getSetting("autologin");
    let intro = await SettingsHelper.getSetting("intro");
    this.setState({
      windowed: windowed,
      widescreen: widescreen,
      autologin: autologin,
      intro: intro,
    });
      (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    window.removeEventListener('stateChanged', this.onStateChanged, false);
    window.removeEventListener('progressChanged', this.onProgressChanged, false);
  }

  onStateChanged(e)
  {
    console.log(e);
    console.log("State changed! New state: " + e.detail.ns + ", old state: " + e.detail.os);
    this.setState({
      state: e.detail.ns
    });
  }

  onProgressChanged(e)
  {
    console.log(e);
    console.log("Progress changed! New progress: " + e.detail.strData);
    this.setState({
      updaterStr: e.detail.strData,
      updaterProc: e.detail.prc
    });
  }

  shouldDisable() {
    return this.state.state == 0 || this.state.state == 1;
  }

  getButtonText() {
    switch(this.state.state)
    {
      case 0:
      case 1:
      case 2:
        return "BUSY";
      case 3:
        return "UPDATE";
      case 4:
        return "INSTALL";
      case 5:
        return "START";
      default:
        return "Say what?";
    }
  }

  getLabelText() {
    switch(this.state.state)
    {
      case 0:
      case 1:
      case 2:
        return "Working...";
      case 3:
        return "Update available!";
      case 4:
        return "Game not installed!";
      case 5:
        return "Ready to start!";
      default:
        return "Say what?";
    }
  }

  handleMainButtonPress() {
    launcherHelper.mainButtonPress();
  }

  handleForceUpdateButtonPress() {
    launcherHelper.forceUpdate();
  }

  async handleChangeSetting(k, v) {
    await SettingsHelper.setSetting(k, v);
    switch(k)
    {
      case "windowed":
        this.setState({windowed: v});
        break;
      case "widescreen":
        this.setState({widescreen: v});
        break;
      case "autologin":
        this.setState({autologin: v});
        break;
      case "intro":
        this.setState({intro: v});
        break;
    }
  }

  render() {
    return (
      <div>
        <style>{`
        .bf2-check {
    background-color: rgba(53, 51, 47, 0.5) !important;
    border-radius: 0px;
    border: 1px solid #6E5C1F;
    font-size: 10px;
    height: 42px;
    line-height: 10px;
    border: 1px solid #6E5C1F !important;
    color: #FFCB00 !important;
    transition: all 0.5s;
    height: 24px;
    width: 24px;
    padding: 6px;
    display: inline-block;
    float: left;
}

.bf2-check:hover {
    cursor: pointer;
    background-color: rgba(255, 210, 0, 0.4) !important;
}
`}</style>
        <Modal isOpen={this.state.settingsModalOpen}>
          <ModalBody>
            <div className="bf2-check" onClick={() => this.handleChangeSetting('windowed', !this.state.windowed)}><i style={{visibility: this.state.windowed ? 'visible' : 'hidden' }} className="fa fa-check"></i></div>
            <div style={{fontSize: "14px", paddingLeft: "8px", lineHeight: "28px", height: "28px", float: "left"}}>Windowed Mode</div>
            <div className="clearfix"></div>
            <div className="bf2-check" onClick={() => this.handleChangeSetting('widescreen', !this.state.widescreen)}><i style={{visibility: this.state.widescreen ? 'visible' : 'hidden' }} className="fa fa-check"></i></div>
            <div style={{fontSize: "14px", paddingLeft: "8px", lineHeight: "28px", height: "28px", float: "left"}}>Enable Widescreen Support</div>
            <div className="clearfix"></div>
            <div className="bf2-check" onClick={() => this.handleChangeSetting('intro', !this.state.intro)}><i style={{visibility: this.state.intro ? 'visible' : 'hidden' }} className="fa fa-check"></i></div>
            <div style={{fontSize: "14px", paddingLeft: "8px", lineHeight: "28px", height: "28px", float: "left"}}>Show Intro Movies</div>
            <Button style={{ float: "right" }} onClick={() => this.setState({ settingsModalOpen: false })}>Close</Button>
          </ModalBody>
        </Modal>
        <Row>
                <Col md={4}>
                    <Card>
                        <CardHeader>Advertisement</CardHeader>
                        <CardBody>
                            <ins className="adsbygoogle"
                                style={{ display: "block" }}
                                data-ad-client="ca-pub-2892579176774543"
                                data-ad-slot="3520336550"
                                data-ad-format="auto"
                                data-full-width-responsive="true"></ins>
                        </CardBody>
                    </Card>
                </Col>
        <Col md={4}>
          <Card>
            <CardHeader>Server Stats(Coming Soon)</CardHeader>
            <CardBody>
              <ul>
                <li>Online Servers: 0</li>
                <li>Online Players: 0</li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardHeader>Player Stats(Coming Soon)</CardHeader>
            <CardBody>
              <ul>
                <li>Kills: 0</li>
                <li>Deaths: 0</li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
            Battlefield 2 is a 2005 first-person shooter military simulator video game, developed by Digital Illusions CE, with contributions by Trauma Studios, and published by Electronic Arts exclusively for Microsoft Windows as the third game in the Battlefield series. <br />
            Players fight in a modern battlefield, using modern weapon systems. Battlefield 2 is a first-person shooter with some strategy and Tactical Shooter elements.
            </CardBody>
            <CardFooter>
              <span>{this.state.updaterStr ? this.state.updaterStr : this.getLabelText()}</span>
              <Progress value={this.state.updaterProc} animated color="warning" className="m-b"  />
              <Button color="success" disabled={this.shouldDisable()} onClick={this.handleMainButtonPress}>{this.getButtonText()}</Button> <Button hidden={true}>Cancel</Button> <Button onClick={this.handleForceUpdateButtonPress} disabled={this.shouldDisable()}>Repair</Button>
              <Button style={{ float: "right" }} onClick={() => this.setState({ settingsModalOpen: true })}>Settings</Button>
            </CardFooter>
          </Card>
        </Row>
      </div>
    );
  }
}

export default Battlefield2;