import React, { Component } from 'react';
import reactFeature from '../../assets/images/react-feature.svg';
import sassFeature from '../../assets/images/sass-feature.svg';
import bootstrapFeature from '../../assets/images/bootstrap-feature.svg';
import responsiveFeature from '../../assets/images/responsive-feature.svg';
import classnames from 'classnames';
import { Card, Button,
  CardHeader,
  CardFooter,
  CardImg,
  CardTitle,
  CardSubtitle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText, CardBody, Row, Col, Progress, Label, Input, UncontrolledAlert } from 'reactstrap';
import { launcherHelper, CefSharpHelper, SettingsHelper } from '../../phoenix/phoenixhelpers';
import { Switch } from '../../vibe';

class Settings extends Component {

  constructor() {
    super();
    this.state = {
      installPath: '',
      requireAdmin: false,
      runStartup: false,
      activeTab: '1',
    }
    this.toggle = this.toggle.bind(this);
    this.openFolderDialog = this.openFolderDialog.bind(this);
  }

  async componentDidMount() {
    await CefSharpHelper.BindObjectAsync('launcher');
    await CefSharpHelper.BindObjectAsync('settings');
      let installPath = await launcherHelper.getGameInstallPath();
      let runOnStartup = await SettingsHelper.getSetting("startup");
      this.setState({ installPath: installPath, runStartup: runOnStartup });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab
        });
    }
  }

  openFolderDialog() {
    launcherHelper.pickNewGameInstallPath().then(x => {
      if(x.change) {
        this.setState({ installPath: x.filePath, requireAdmin: x.requireAdmin });
        alert("Please restart the launcher to make the changes take effect!\nExisting installations will not be moved.");
      }
    });
  }

  render() {
    return (
      <Card body>
            <div className="full-bleed">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                        >
                        General
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                        >
                        Phoenix Heroes
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3'); }}
                        >
                        Phoenix Play4Free
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                        <Col md={4} xs={12}>
            <Card>
              <CardHeader>
                Game Installation Path
                <Button size="sm" className="pull-right" onClick={this.openFolderDialog}>
                  Change
                </Button>
              </CardHeader>
              <CardBody>
                <Input type="text" name="name" id="name" placeholder="Game Install Path" value={this.state.installPath} disabled={true} />
              </CardBody>
            </Card>
          </Col>
                        </Row>
                        <Row>
                          <Col md={4} xs={12}>
                          <Card>
                            <CardHeader>
                              Run launcher on startup
                            </CardHeader>
                            <CardBody>
                            <Switch
                              enabled={this.state.runStartup}
                              toggle={() => {
                                  SettingsHelper.setSetting("startup", !this.state.runStartup);
                                this.setState(prevState => ({ runStartup: !prevState.runStartup }));
                              }}
                            />
                            </CardBody>
                          </Card>
                          </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                        <Col sm="12">
                            <h4>More content.</h4>
                        </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        </Card>
    );
  }
}

export default Settings;