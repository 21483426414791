import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './registerServiceWorker';
import * as Sentry from "@sentry/react";
import App from './App';

Sentry.init({
    dsn: "https://b5fb6224f12b4a22b9dab93a3ffa70c2@sentry.phoenixnetwork.net/5"
  });

ReactDOM.render(<App />, document.getElementById('app'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
