import React, {Component} from 'react';
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import { SubNavigation } from '../../vibe';

export default class Account extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const NAV = [
      {
        name: 'Information',
        url: '/pages/subnav'
      },
      {
        name: 'Link',
        url: '/#'
      }
    ];
    return (
      <div>
        Nothing here yet
      </div>
    );
  };
}