import React, { Component } from 'react';
import reactFeature from '../../assets/images/react-feature.svg';
import sassFeature from '../../assets/images/sass-feature.svg';
import bootstrapFeature from '../../assets/images/bootstrap-feature.svg';
import responsiveFeature from '../../assets/images/responsive-feature.svg';
import classnames from 'classnames';
import {
    Card, Button,
    CardHeader,
    CardFooter,
    CardImg,
    CardTitle,
    CardSubtitle,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    CardText, CardBody, Row, Col, Progress, Label, Input, UncontrolledAlert
} from 'reactstrap';
import { launcherHelper, CefSharpHelper, ModerationHelper } from '../../phoenix/phoenixhelpers';
import { Switch } from '../../vibe';

class Moderation extends Component {

    constructor() {
        super();
        this.state = {
            activeTab: '1',
        }
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        await CefSharpHelper.BindObjectAsync('moderation');
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>
                <div className="full-bleed">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                Overview
                        </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Player Search
                        </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}
                            >
                                Server Search
                        </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col md={4} xs={12}>
                                    <Card>
                                        <CardHeader>
                                            
                                        </CardHeader>
                                        <CardBody>
                                            
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={4} xs={12}>
                                    <Card>
                                        <CardHeader>
                                            Product Sold{' '}
                                            <Button size="sm" className="pull-right">
                                                View
                </Button>
                                        </CardHeader>
                                        <CardBody>
                                            <h2 className="m-b-20 inline-block">
                                                <span>1,890</span>
                                            </h2>{' '}
                                            <i className="fa fa-caret-up text-danger" aria-hidden="true" />
                                            <Progress value={77} color="success" />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={4} xs={12}>
                                    <Card>
                                        <CardHeader>
                                            Server Capacity{' '}
                                            <Button size="sm" className="pull-right">
                                                View
                </Button>
                                        </CardHeader>
                                        <CardBody>
                                            <h2 className="inline-block">
                                                <span>14%</span>
                                            </h2>
                                            <Progress value={14} color="primary" />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} xs={12}>
                                    <Card>
                                        <CardHeader>
                                            
                                        </CardHeader>
                                        <CardBody>
                                            
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <h4>More content.</h4>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        );
    }
}

export default Moderation;