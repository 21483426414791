import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import NavSpacer from './components/NavSpacer';
import NavOverlay from './components/NavOverlay';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import PageAlertContext from '../PageAlert/PageAlertContext';
import { launcherHelper, CefSharpHelper, MainHelper } from '../../../phoenix/phoenixhelpers';

export default class SidebarNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstRun: false,
      games: {},
      user: null,
      moderationTab: {},
    };
  }

  async componentDidMount() {
    await CefSharpHelper.BindObjectAsync('launcher');
    let games = await launcherHelper.getGames();
    var gameChilds = [];
    for (var i = 0; i < games.length; i++) {
      var entry = { name: games[i].formattedName, url: "/games/" + games[i].name };
      gameChilds.push(entry);
    }
    var gamesItem = {
      name: 'Games',
      icon: 'Monitor',
      children: gameChilds
      };
    var moderationItem = {
        name: 'Moderation',
        url: '/moderation',
        icon: 'user-check',
    };
    let user = await launcherHelper.getUser();
    this.setState({ games: gamesItem, moderationTab: moderationItem, user: user });
  }

  render() {
    const navItems = items => {
      return items.map((item, index) => itemType(item, index));
    };

    const itemType = (item, index) => {
      if(item && item.name) {
        if (item.children) {
          return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} />;
        } else if (item.divider) {
          return <NavDivider key={index} />;
        } else {
          return <NavSingleItem item={item} key={index} />;
      }
    }
    };

    const NavBrand = ({ logo, logoText }) => {
      return (
        <div className="site-logo-bar">
          <NavLink to="/" className="navbar-brand">
            {logo && <img src={logo} alt="" />}
            {logoText && <span className="logo-text">{logoText}</span>}
          </NavLink>
        </div>
      );
    };

    return (
      <PageAlertContext.Consumer>
        {consumer => {
          const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
          return (
            <div>
              <div className={`app-sidebar ${hasPageAlertClass}`}>
                <NavBrand logo={this.props.logo} logoText={this.props.logoText} />
                <nav>
                  <ul id="main-menu">
                    {navItems(this.props.nav.top)}
                    {this.state.games ? itemType(this.state.games, 8) : null}
                    <NavSpacer />
                    {this.state.user != null && this.state.user.Staff ? itemType(this.state.moderationTab, 9) : null}
                    {navItems(this.props.nav.bottom)}
                  </ul>
                </nav>
              </div>
              {this.props.isSidebarCollapsed && <NavOverlay onClick={this.props.toggleSidebar} />}
            </div>
          );
        }}
      </PageAlertContext.Consumer>
    );
  }
}
