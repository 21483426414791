import React, { Component } from 'react';
import reactFeature from '../../assets/images/react-feature.svg';
import sassFeature from '../../assets/images/sass-feature.svg';
import bootstrapFeature from '../../assets/images/bootstrap-feature.svg';
import responsiveFeature from '../../assets/images/responsive-feature.svg';
import { Card, Button,
  CardHeader,
  CardFooter,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardText, CardBody, Row, Col, Progress } from 'reactstrap';
import { launcherHelper } from '../../phoenix/phoenixhelpers';
import axios from 'axios';

class GhostReconPhantoms extends Component {

  constructor() {
    super();
    this.state = {
      dropdownOpen: false,
      state: 0,
      updaterStr: null,
      updaterProc: 0,
      isDonator: false,
    }
    this.onStateChanged = this.onStateChanged.bind(this);
    this.onProgressChanged = this.onProgressChanged.bind(this);
    this.shouldDisable = this.shouldDisable.bind(this);
    this.getButtonText = this.getButtonText.bind(this);
    this.getLabelText = this.getLabelText.bind(this);
    this.handleMainButtonPress = this.handleMainButtonPress.bind(this);
    this.handleForceUpdateButtonPress = this.handleForceUpdateButtonPress.bind(this);
  }

  async componentDidMount() {
    await launcherHelper.switchGame("PhoenixHeroes");
    let state = await launcherHelper.loadGameState("PhoenixHeroes");
    console.log(state);
    if(this.state.state == 0)
    {
      this.setState({
        state: state.uState
      });
    }
    window.addEventListener('stateChanged', this.onStateChanged, false);
      window.addEventListener('progressChanged', this.onProgressChanged, false);
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      let user = launcherHelper.getUser();
      this.setState({
          isDonator: user.Donator
      });
  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    window.removeEventListener('stateChanged', this.onStateChanged, false);
    window.removeEventListener('progressChanged', this.onProgressChanged, false);
  }

  onStateChanged(e)
  {
    console.log(e);
    console.log("State changed! New state: " + e.detail.ns + ", old state: " + e.detail.os);
    this.setState({
      state: e.detail.ns
    });
    if(e.detail.ns === 5)
    {
      this.setState({
        updaterStr: this.getLabelText(),
      });
    }
  }

  onProgressChanged(e)
  {
      if (this.state.state == 2 || this.state.state == 1 || this.state.state == 0) {
          console.log(e);
          console.log("Progress changed! New progress: " + e.detail.strData);
          this.setState({
              updaterStr: e.detail.strData,
              updaterProc: e.detail.prc
          });
      }
  }

  shouldDisable() {
    return this.state.state == 0 || this.state.state == 1 || this.state.state == 2;
  }

  shouldDisableFU() {
    return this.state.state != 5;
  }

  getButtonText() {
    switch(this.state.state)
    {
      case 0:
      case 1:
      case 2:
        return "BUSY";
      case 3:
        return "UPDATE";
      case 4:
        return "INSTALL";
      case 5:
        return "START";
      default:
        return "Say what?";
    }
  }

  getLabelText() {
    switch(this.state.state)
    {
      case 0:
      case 1:
      case 2:
        return "Working...";
      case 3:
        return "Update available!";
      case 4:
        return "Game not installed!";
      case 5:
        return "Ready to start!";
      default:
        return "Say what?";
    }
  }

  handleMainButtonPress() {
    launcherHelper.mainButtonPress();
  }

  handleForceUpdateButtonPress() {
    launcherHelper.forceUpdate();
  }

  render() {
    const heroStyles = {
      padding: '50px 0 70px'
    };

    return (
      <div>
        <Row>
        {this.state.isDonator ? null : <Col md={4}>
          <Card>
            <CardHeader>Advertisement</CardHeader>
            <CardBody>
                            <ins className="adsbygoogle"
                                style={{ display: "block" }}
                                data-ad-client="ca-pub-2892579176774543"
                                data-ad-slot="3520336550"
                                data-ad-format="auto"
                                data-full-width-responsive="true"></ins>
            </CardBody>
          </Card>
        </Col>}
        </Row>
        <Row>
          <Card>
            <CardBody>
            
            </CardBody>
            <CardFooter>
              <span>{this.state.updaterStr ? this.state.updaterStr : this.getLabelText()}</span>
              <Progress value={this.state.updaterProc} animated color="warning" className="m-b"  />
              <Button color="success" disabled={this.shouldDisable()} onClick={this.handleMainButtonPress}>{this.getButtonText()}</Button> <Button hidden={true}>Cancel</Button> <Button onClick={this.handleForceUpdateButtonPress} disabled={this.shouldDisable()}>Repair</Button>
            </CardFooter>
          </Card>
        </Row>
      </div>
    );
  }
}

export default GhostReconPhantoms;
