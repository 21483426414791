export default {
  top: [
    {
      name: 'Home',
      url: '/home',
      icon: 'Home',
    },
  ],
  bottom: [
    {
      name: 'Settings',
      url: '/settings',
      icon: 'Settings',
    },
    {
      name: 'Account',
      url: '/account',
      icon: 'User',
    },
  ],
};
