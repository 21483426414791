import axios from 'axios';
import {
    ServerEventsClient,
    ServerEventConnect,
    ServerEventMessage,
    ServerEventCommand,
    splitOnFirst,
} from "servicestack-client";
import Swal from 'sweetalert2';

window.isCefLauncher = typeof CefSharp !== "undefined";
if (!window.isCefLauncher) {
    if (window.readyStateChanged == undefined) {
        window.readyStateChanged = new Event('launcherReady');
    }
    if (window.stateChangedEvent == undefined) {
        window.stateChangedEvent = new Event('stateChanged');
    }
    if (window.eventsConnected == undefined) {
        window.eventsConnected = new Event('eventsConnected');
    }
    if (window.eventsListener == undefined) {
        window.eventsListenerClient = null;
        window.eventsListener = () => {
            let BASEURL = "http://localhost:7845";
            let CHANNEL = "launcher";
            if (window.eventsListenerClient != null)
                window.eventsListenerClient.stop();

            console.log(`Connecting to ${BASEURL} on channel ${CHANNEL}`);
            window.eventsListenerClient = new ServerEventsClient(BASEURL, [CHANNEL], {
                handlers: {
                    onConnect: (e) => {
                        console.log("connect");
                    },
                    onJoin: (e) => {
                        console.log("join");
                    },
                    onLeave: (e) => {
                        console.log("leave");
                    },
                    onUpdate: (e) => {
                        console.log("update");
                    },
                    onMessage: (e) => {
                        console.log("message");
                    },
                    showMsg: (msg, e) => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: JSON.parse(msg).msg,
                        })
                    },
                    stateChanged: (state, e) => {
                        console.log("State changed: " + state);
                        window.dispatchEvent(new CustomEvent("stateChanged", { detail: JSON.parse(state) }));
                    },
                    progressChanged: (progress, e) => {
                        console.log("Progress changed: " + progress);
                        window.dispatchEvent(new CustomEvent("progressChanged", { detail: JSON.parse(progress) }));
                    },
                },
                onException: e => {
                    document.dispatchEvent(new CustomEvent("eventsConnected", { detail: JSON.stringify(e) }));
                }
            }).start();
        }
        window.eventsListener();
    }
}
const delay = millis => new Promise((resolve, reject) => {
    setTimeout(_ => resolve(), millis)
});

export class launcherHelper
{
    static async isActive()
    {
        if (window.isCefLauncher) {
            return true;
        }
        else {
            try {
                let response = await axios.get("http://localhost:7845/launcher/ping");
                if (response.status !== 200) {
                    return false;
                }
                return response.data == "pong";
            } catch (error) {
                return false;
            }
        }
    }
    static async getGames()
    {
        if (window.isCefLauncher) {
            return await eval('launcher.getGames();');
        }
        else {
            return (await axios.get("http://localhost:7845/launcher/games")).data;
        }
    }
    static async getUser()
    {
        if (window.isCefLauncher) {
            return await eval('launcher.getUser();');
        }
        else {
            return (await axios.get("http://localhost:7845/launcher/user")).data;
        }
    }
    static async isFirstRun()
    {
        if (window.isCefLauncher) {
            return await eval('launcher.isFirstRun();');
        }
        else {
            return (await axios.get("http://localhost:7845/launcher/firstRun")).data;
        }
    }
    static async firstRunComplete()
    {
        if (window.isCefLauncher) {
            return await eval('launcher.firstRunComplete();');
        }
        else {
            return (await axios.post("http://localhost:7845/launcher/firstRunComplete", ""));
        }
    }
    static async getGameInstallPath()
    {
        if (window.isCefLauncher) {
            return await eval('launcher.getGameInstallPath();');
        }
        else {
            return (await axios.get("http://localhost:7845/launcher/gameInstallPath")).data;
        }
    }
    static async pickNewGameInstallPath()
    {
        if (window.isCefLauncher) {
            return await eval('launcher.pickNewGameInstallPath();');
        }
        else {
            return await delay(1);
        }
    }
    static async switchGame(game)
    {
        if (window.isCefLauncher) {
            return await eval('launcher.switchGame("' + game + '");');
        }
        else {
            let dict = {};
            dict["Data"] = game;
            return (await axios.post("http://localhost:7845/launcher/switchGame", dict));
        }
    }
    static async loadGameState(game)
    {
        if (window.isCefLauncher) {
            return await eval('launcher.loadGameState("' + game + '");');
        }
        else {
            let dict = {};
            dict["Data"] = game;
            return (await axios.post("http://localhost:7845/launcher/gameState", dict)).data;
        }
    }
    static async registerCallbacks(stateChanged)
    {
        if (window.isCefLauncher) {
            return await eval('launcher.registerCallbacks(' + stateChanged + ');');
        }
        else {
            return await delay(1);
        }
    }
    static async mainButtonPress()
    {
        if (window.isCefLauncher) {
            return await eval('launcher.mainButtonPress();');
        }
        else {
            let dict = {};
            dict["Data"] = "";
            return (await axios.post("http://localhost:7845/launcher/mainButton", dict));
        }
    }
    static async forceUpdate()
    {
        if (window.isCefLauncher) {
            return await eval('launcher.forceUpdate();');
        }
        else {
            let dict = {};
            dict["Data"] = "";
            return (await axios.post("http://localhost:7845/launcher/forceUpdate", dict));
        }
    }
    static async login(token)
    {
        if (window.isCefLauncher) {
            return await delay(1);
        }
        else {
            let dict = {};
            dict["Data"] = token;
            return (await axios.post("http://localhost:7845/launcher/login", dict));
        }
    }
    static async logout()
    {
        if (window.isCefLauncher) {
            return await eval('launcher.logout();');
        }
        else {
            return await delay(1);
        }
    }
    static async startLauncher()
    {
        setTimeout(function () {
            window.protocolCheck("phoenix://start",
                function () {
                    document.dispatchEvent(window.readyStateChanged);
                },
                function () {
                    document.dispatchEvent(window.readyStateChanged);
                });
        }, 200);
    }
}
export class CefSharpHelper
{
    static async BindObjectAsync(object)
    {
        if (window.isCefLauncher) {
            return await eval("CefSharp.BindObjectAsync('" + object + "');");
        }
        else {
            return await delay(1);
        }
    }
}
export class SettingsHelper
{
    static async setGameInstallPath(path)
    {
        if (window.isCefLauncher) {
            return await eval('settings.setGameInstallPath("' + path + '");');
        }
        else {
            let dict = {};
            dict["Data"] = path;
            return (await axios.post("http://localhost:7845/settings/installPath", dict));
        }
    }
    static async getSetting(key)
    {
        if (window.isCefLauncher) {
            return await eval('settings.getSetting("' + key + '");');
        }
        else {
            let dict = {};
            dict["Data"] = key;
            return (await axios.post("http://localhost:7845/settings/get", dict));
        }
    }
    static async setSetting(key, value)
    {
        if (window.isCefLauncher) {
            return await eval('settings.setSetting("' + key + '", "' + value + '");');
        }
        else {
            let dict = {};
            dict["Data"] = [];
            dict["Data"][0] = key;
            dict["Data"][1] = value;
            return (await axios.post("http://localhost:7845/settings/set", dict));
        }
    }
    static async getLauncherSetting(key)
    {
        if (window.isCefLauncher) {
            return await eval('settings.getLauncherSetting("' + key + '");');
        }
        else {
            let dict = {};
            dict["Data"] = key;
            return (await axios.post("http://localhost:7845/settings/lget", dict));
        }
    }
    static async setLauncherSetting(key, value)
    {
        if (window.isCefLauncher) {
            return await eval('settings.setLauncherSetting("' + key + '", "' + value + '");');
        }
        else {
            let dict = {};
            dict["Data"] = [];
            dict["Data"][0] = key;
            dict["Data"][1] = value;
            return (await axios.post("http://localhost:7845/settings/lset", dict));
        }
    }
}
export class ModerationHelper
{
    static async loadData() {
        return await eval('moderation.loadData();');
    }
}