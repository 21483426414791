import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UncontrolledAlert, Button, Label, Input, Modal, NavItem, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Header, SidebarNav, Footer, PageContent, Avatar, PageAlert, Page, Loader } from '../vibe';
import { launcherHelper, CefSharpHelper, SettingsHelper } from '../phoenix/phoenixhelpers';
import Logo from '../assets/images/phoenix.png';
import avatar1 from '../assets/images/avatar1.png';
import nav from '../_nav';
import routes from '../views';
import ContextProviders from '../vibe/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../vibe/helpers/handleTabAccessibility';
import axios from "axios";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

const MOBILE_SIZE = 992;

export default class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      user: {},
      modal: false,
      installPath: "",
      requireAdmin: false,
      ready: false,
      loggedIn: false,
      serverEventsConnected: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.openFolderDialog = this.openFolderDialog.bind(this);
    this.startLauncher = this.startLauncher.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onServerEventsConnected = this.onServerEventsConnected.bind(this);
    document.addEventListener('eventsConnected', this.onServerEventsConnected, false);
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  async componentDidMount() {
    await CefSharpHelper.BindObjectAsync('launcher');
    await CefSharpHelper.BindObjectAsync('settings');
    launcherHelper.isActive().then(x => {
        if (!x) {
            document.addEventListener('launcherReady', function (e) {
                console.log("done");
                launcherHelper.getUser().then(user => {
                    if (!user || user.Error) {
                        console.log("User is not logged in");
                        this.setState({ loggedIn: false });
                    } else {
                        this.loadData();
                    }
                });
            }, false)
        }
        if (x) {
            launcherHelper.getUser().then(user => {
                if (!user || user.Error) {
                    console.log("User is not logged in");
                    let launcherToken = Cookies.get('launcherToken');
                    console.log(launcherToken);
                    if (launcherToken == undefined || launcherToken == 'undefined') {
                        window.location.href = "https://launcher.phoenixnetwork.net/api/login";
                        return;
                    }
                    launcherHelper.login(launcherToken).then(x => {
                        console.log(x);
                        if (x.status == 200) {
                            if (x.data.result == "success") {
                                this.loadData();
                                return;
                            }
                        }
                        console.log("Something went wrong");
                    });
                    this.setState({ loggedIn: false });
                } else {
                    this.loadData();
                }
            });
        }
        this.setState({ ready: x });
    });
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
  }

  async loadData() {
    let user = await launcherHelper.getUser();
    let isFirst = await launcherHelper.isFirstRun();
    let installPath = await launcherHelper.getGameInstallPath();
    this.setState({ user: user, modal: isFirst, installPath: installPath, loggedIn: user != null });
  }

  onServerEventsConnected(event) {
    console.log("ServerEventsConnectedResult: " + event.detail);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('eventsConnected', this.onServerEventsConnected);
  }

  toggleSideCollapse = () => {
    this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  toggleModal() {
    this.setState(prevState => ({
        modal: !prevState.modal
    }));
  }

  saveSettings() {
    SettingsHelper.setGameInstallPath(this.state.installPath);
    launcherHelper.firstRunComplete();
    this.toggleModal();//hide it
  }

  openFolderDialog() {
    launcherHelper.pickNewGameInstallPath().then(x => {
      this.setState({ installPath: x.filePath, requireAdmin: x.requireAdmin });
    });
  }

  startLauncher() {
      launcherHelper.startLauncher();
  }

  render() {
    const { sidebarCollapsed, ready, loggedIn } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';
    return (
        ready ? ( loggedIn ?
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <div className="app-body">
            <SidebarNav
              nav={nav}
              logo={Logo}
              logoText="Phoenix Launcher"
              isSidebarCollapsed={sidebarCollapsed}
              toggleSidebar={this.toggleSideCollapse}
              {...this.props}
            />
            <Page>
                    <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>It looks like you are here for the first time!</ModalHeader>
                        <ModalBody>
                            Welcome! We know you are eager to get started but first there are some things that we must ask.
                            <br />
                          <Label for="gameInstallPath">Game Install Path</Label>
                          <Input
                            type="text"
                            name="message"
                            id="gameInstallPath"
                            placeholder="Some Path, why are you seeing this?"
                            value={this.state.installPath}
                            readOnly={true}
                            onClick={this.openFolderDialog}
                          />
                          <br />
                          <UncontrolledAlert color="danger" hidden={!this.state.requireAdmin}>
                            The path you selected will require you to grant Phoenix Launcher administrative privileges!
                          </UncontrolledAlert>
                          <br />

                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.saveSettings}>Save</Button>{' '}
                            <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={false}>
                      <ModalBody>
                        
                      </ModalBody>
                    </Modal>
              <Header
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                routes={routes}
                {...this.props}
              >
                <HeaderNav {...this.state.user} />
              </Header>
              <PageContent>
                <Switch>
                  {routes.map((page, key) => (
                    <Route path={page.path} component={page.component} key={key} />
                  ))}
                  <Redirect from="/" to="/home" />
                </Switch>
              </PageContent>
            </Page>
            
          </div>
          <Footer>
          <span>Copyright © { new Date().getFullYear() } Phoenix Network. All rights reserved.</span>
            <span><a href="#!">Terms</a> | <a href="#!">Privacy Policy</a></span>
            <span className="ml-auto hidden-xs">
              Made with{' '}
              <span role="img" aria-label="heart" style={{ color: "red" }}>
                ❤
              </span>
            </span>
          </Footer>
        </div>
            </ContextProviders> :
            <div>
                <div className="mainContent">
                    <Modal isOpen={true}>
                        <ModalHeader>Performing login!</ModalHeader>
                        <ModalBody>
                            Connecting and authenticating to Phoenix servers...
                            <br />
                            <Loader type="spin" />
                        </ModalBody>
                    </Modal>
                </div>
            </div>
                ) :
            <div>
                <div className="mainContent">
                    <Modal isOpen={true}>
                        <ModalHeader>No communication with the launcher!</ModalHeader>
                        <ModalBody>
                            The launcher has to be running for you to use this site. If you have the launcher installed you can start it below or download it.
                            <br />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.startLauncher}>Start</Button>{' '}
                            <Button color="secondary" onClick={() => window.location.href = "https://cdn.phoenixnetwork.net/updater/versions/client/Launcher/PhoenixInstaller.exe"}>Download</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
    );
  }
}

function HeaderNav(user) {
  console.log(user);
  return (
    <React.Fragment>
      <NavItem>
        <span className="m-r"><span aria-hidden="true" className="fa fa-circle text-success"></span></span><span className="sr-only">Status</span>Messages
      </NavItem>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <Avatar size="small" color="blue" initials="LC" /> { user ? user.Username : "Loading" }
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={launcherHelper.logout}>Logout</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
}
