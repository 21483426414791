import React, { Component } from 'react';
import reactFeature from '../../assets/images/react-feature.svg';
import sassFeature from '../../assets/images/sass-feature.svg';
import bootstrapFeature from '../../assets/images/bootstrap-feature.svg';
import responsiveFeature from '../../assets/images/responsive-feature.svg';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { launcherHelper, CefSharpHelper, MainHelper } from '../../phoenix/phoenixhelpers';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }
  
  async componentDidMount() {
    await CefSharpHelper.BindObjectAsync('launcher');
    let user = await launcherHelper.getUser();
    this.setState({ user: user });
  }

  render() {
    var subtitle;
    const heroStyles = {
      padding: '50px 0 70px'
    };

    return (
      <div>
        <Row>
          <Col md={6}>
            <div className="home-hero" style={heroStyles}>
              <h1>Welcome {this.state.user ? this.state.user.Username : null}, to Phoenix.</h1>
              <p className="text-muted">
                The place to play multiplayer games from your childhood
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
